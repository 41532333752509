<template>
    <div class="public_charity">
        <img style="width: 100%;" src="@/assets/index/zc.jpg" alt=""> 
        <div class="public_charity_text">
            <div class="public_charity_text_title">秦皇岛市邢台商会章程</div>
            <div class="public_charity_text_assistant_title">第一章 总则</div>
            <div class="public_charity_text_paragraph">
                <span>第一条</span>
                秦皇岛市邢台商会是由河北省邢台籍的自然人或法人在秦皇岛市投资兴办，经登记机关注册登记的企业自愿联合发起成立，以促进邢秦两地经贸合作为宗旨的联合性、非营利性社会团体。
            </div>
            <div class="public_charity_text_paragraph">
                本会会员分布和活动地域为秦皇岛市。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第二条</span>
                本会的宗旨是：以法治会、以德兴会、以商养会、以会强商，整合邢秦两地资源，展现邢台精英风彩。努力建设最具创造力、最具凝聚力、最富社会责任感的商会。
            </div>
            <div class="public_charity_text_paragraph">
                本会遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，自觉加强诚信自律建设。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第三条</span>
                本会坚持中国共产党的全面领导，根据《中国共产党章程》的规定，设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件，承担保证政治方向、团结凝聚群众、推动事业发展、建设先进文化、服务人才成长、加强自身建设等职责。
            </div>
            <div class="public_charity_text_paragraph">
                本会的业务主管单位是秦皇岛市工商业联合会，登记机关是秦皇岛市行政审批局，管理机关是秦皇岛市民政局，党建领导机关是中共秦皇岛市工商联非公经济商（协）会委员会。
            </div>
            <div class="public_charity_text_paragraph">
                本会接受登记机关、管理机关、党建领导机关、有关行业管理部门的业务指导和监督管理。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第四条</span>
                本会负责人包括理事长、副理事长、秘书长。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第五条</span>
                本会的住所设在秦皇岛市经济技术开发区松花江西道3-1号一层104室。
            </div>
            <div class="public_charity_text_assistant_title">第二章 党建工作</div>
            <div class="public_charity_text_paragraph">
                <span>第六条</span>
                本商会依照《中国共产党章程》有关规定建立秦皇岛市邢台商会党支部，隶属中共秦皇岛市工商联非公经济商（协）会委员会。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第七条</span>
                本商会党组织在商会中发挥政治领导作用，做到把方向、议大事、促落实。积极开展统战工作，建设先进文化，对商会重大问题决策、重要人事任免、重大项目投资决策、大额资金使用等提出意见建议，以党建带会建。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第八条</span>
                本商会党组织负责人与领导层党员实行双向进入、交叉任职。商会为党组织开展活动提供必要条件和支持。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第九条</span>
                本商会可按照有关规定设立工会、青年工作委员会、群众工作中心等组织，党组织负责对工会、青年工作委员会、群众工作中心等组织业务工作进行领导。
            </div>
            
            <div class="public_charity_text_assistant_title">第三章 业务范围</div>
            <div class="public_charity_text_paragraph">
                <span>第十条</span>
                本会的业务范围：
            </div>
            <div class="public_charity_text_paragraph">
                （一）协调企业与企业之间、企业与政府之间的关系，促进交流合作，发挥桥梁纽带作用；
            </div>
            <div class="public_charity_text_paragraph">
                （二）提供相关法律法规和政策咨询，编辑信息刊物，搜集市场信息，宣传两地投资环境，开展业务培训;    
            </div>
            <div class="public_charity_text_paragraph">
                （三）开拓投资融资渠道，帮助企业增强发展能力，开展招商引资、经济考察、展览展销、经贸合作等商务服务，促进企业发展和两地经济发展；
            </div>
            <div class="public_charity_text_paragraph">
                （四）为会员企业排忧解难，依法维护会员合法权益，向政府反映会员的合理诉求;
            </div>
            <div class="public_charity_text_paragraph">
                （五）加强会员诚信自律建设，促进会员诚信经营，维护公平竞争和经济秩序；
            </div>
            <div class="public_charity_text_paragraph">
                （六）组织会员和企业参加社会公益慈善事业，为创建和谐社会承担社会责任;
            </div>
            <div class="public_charity_text_paragraph">
                （七）接受两地政府及相关职能部门授权或者委托的其他事项。
            </div>
            <div class="public_charity_text_paragraph">
                业务范围中属于法律法规规章规定须经批准的事项，依法经批准后开展。
            </div>

            <div class="public_charity_text_assistant_title">第四章 会员</div>

            <div class="public_charity_text_paragraph">
                <span>第十一条</span>
                本会的会员为单位会员。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第十二条</span>
                拥护本会章程，符合下列条件的，可以自愿申请加入本会：
            </div>
            <div class="public_charity_text_paragraph">
                （一）拥护本团体的章程；
            </div>
            <div class="public_charity_text_paragraph">
                （二）有加入本团体的意愿；
            </div>
            <div class="public_charity_text_paragraph">
                （三）在本团体的业务领域内具有一定的影响。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第十三条</span>
                会员入会的程序是：
            </div>
            <div class="public_charity_text_paragraph">
                （一）提交入会申请书；
            </div>
            <div class="public_charity_text_paragraph">
                （二）经1名以上会员介绍；
            </div>
            <div class="public_charity_text_paragraph">
                （三）提交有关证明材料，包括：
                <div style="text-indent: 5em;">
                    1.个人身份信息；<br> 
                </div>
                <div style="text-indent: 5em;">
                    2.企业信息；
                </div>
            </div>
            <div class="public_charity_text_paragraph">
                （四）由理事会讨论通过；
            </div>
            <div class="public_charity_text_paragraph">
                （五）由本会颁发会员证，并予以公告。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第十四条</span>
                会员享有下列权利：
            </div>
            <div class="public_charity_text_paragraph">
                （一）选举权、被选举权和表决权；
            </div>
            <div class="public_charity_text_paragraph">
                （二）对本会工作的知情权、建议权和监督权；
            </div>
            <div class="public_charity_text_paragraph">
                （三）参加本会活动并获得本会服务的优先权；
            </div>
            <div class="public_charity_text_paragraph">
                （四）退会自由。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第十五条</span>
                会员履行下列义务：
            </div>
            <div class="public_charity_text_paragraph">
                （一）遵守本会的章程和各项规定；
            </div>
            <div class="public_charity_text_paragraph">
                （二）执行本会的决议；
            </div>
            <div class="public_charity_text_paragraph">
                （三）按规定交纳会费；
            </div>
            <div class="public_charity_text_paragraph">
                （四）维护本会的合法权益；
            </div>
            <div class="public_charity_text_paragraph">
                （五）向本会反映情况，提供有关资料。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第十六条</span>
                会员如有违反法律法规和本章程的行为，经理事会表决通过，给予下列处分：
            </div>
            <div class="public_charity_text_paragraph">
                （一）警告；
            </div>
            <div class="public_charity_text_paragraph">
                （二）通报批评；
            </div>
            <div class="public_charity_text_paragraph">
                （三）暂停行使会员权利；
            </div>
            <div class="public_charity_text_paragraph">
                （四）除名。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第十七条</span>
                会员退会须书面通知本会并交回会员证。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第十八条</span>
                会员有下列情形之一的，自动丧失会员资格：
            </div>
            <div class="public_charity_text_paragraph">
                （一）2年不按规定交纳会费；
            </div>
            <div class="public_charity_text_paragraph">
                （二）2年不按要求参加本会活动；
            </div>
            <div class="public_charity_text_paragraph">
                （三）不再符合会员条件；
            </div>
            <div class="public_charity_text_paragraph">
                （四）丧失民事行为能力。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第十九条</span>
                会员退会、自动丧失会员资格或者被除名后，其在本会相应的职务、权利、义务自行终止。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第二十条</span>
                本会置备会员名册，对会员情况进行记载。会员情况发生变动的，应当及时修改会员名册，并向会员公告。
            </div>

            <div class="public_charity_text_assistant_title">第五章 组织机构</div>
            <div class="public_charity_text_assistant_assistant_title">第一节 会员大会</div>
            <div class="public_charity_text_paragraph">
                <span> 第二十一条</span>
               会员大会是本会的最高权力机构，其职权是：
            </div>
            <div class="public_charity_text_paragraph">
                （一）制定和修改章程；
            </div>
            <div class="public_charity_text_paragraph">
                （二）决定本会的工作目标和发展规划；
            </div>
            <div class="public_charity_text_paragraph">
                （三）制定和修改理事、负责人产生办法，报党建领导机关备案；
            </div>
            <div class="public_charity_text_paragraph">
                （四）选举和罢免理事、监事;
            </div>
            <div class="public_charity_text_paragraph">
                （五）制定和修改会费标准；
            </div>
            <div class="public_charity_text_paragraph">
                （六）审议理事会的工作报告和财务报告；
            </div>
            <div class="public_charity_text_paragraph">
                （七）决定名誉职务的设立；
            </div>
            <div class="public_charity_text_paragraph">
                （八）决定名称变更事宜；
            </div>
            <div class="public_charity_text_paragraph">
                （九）决定终止事宜；
            </div>
            <div class="public_charity_text_paragraph">
                （十）决定其他重大事宜。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第二十二条</span>
                会员大会每1年召开1次。本会召开会员大会，须提前15日将会议的议题通知会员。
            </div>
            <div class="public_charity_text_paragraph">
                会员大会应当采用现场表决方式。
            </div>
            <div class="public_charity_text_paragraph">
                <span>  第二十三条</span>
              经理事会或者本会70%以上的会员提议，应当召开临时会员大会。
            </div>
            <div class="public_charity_text_paragraph">
                临时会员大会由理事长主持。理事长不主持或不能主持的，由提议的理事会或会员推举本会一名负责人主持。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第二十四条</span>
                会员大会须有2／3以上的会员出席方能召开，决议事项符合下列条件方能生效：
            </div>
            <div class="public_charity_text_paragraph">
                （一）制定和修改章程，决定本会终止，须经到会会员2/3 以上表决通过；
            </div>
            <div class="public_charity_text_paragraph">
                （二）选举理事，当选理事得票数不得低于到会会员的1/2；罢免理事，须经到会会员1/2以上投票通过；
            </div>
            <div class="public_charity_text_paragraph">
                （三）制定或修改会费标准，须经到会会员1/2以上无记名投票方式表决；
            </div>
            <div class="public_charity_text_paragraph">
                （四）其他决议，须经到会会员1/2以上表决通过。
            </div>
            <div class="public_charity_text_assistant_assistant_title">第二节 理事会</div>
            <div class="public_charity_text_paragraph">
                <span>第二十五条</span>
                理事会是会员大会的执行机构，在会员大会闭会期间领导本会开展工作，对会员大会负责。理事人数最多不得超过12人，不能来自同一会员单位。
            </div>
            <div class="public_charity_text_paragraph">
                本会理事应当符合以下条件：
            </div>
            <div class="public_charity_text_paragraph">
                （一）具有完全民事行为能力；
            </div>
            <div class="public_charity_text_paragraph">
                （二）诚实守信，无犯罪记录；
            </div>
            <div class="public_charity_text_paragraph">
                （三）具有决策能力。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第二十六条</span>
               理事的选举和罢免：
            </div>
            <div class="public_charity_text_paragraph">
                （一）第一届理事由发起人申请成立时的会员共同提名，报党建领导机关同意后，会员大会选举产生；
            </div>
            <div class="public_charity_text_paragraph">
                （二）理事会换届，应当在会员大会召开前2个月，由理事会提名，成立由理事代表、监事代表、党组织代表和会员组成的换届工作领导小组；
            </div>
            <div class="public_charity_text_paragraph">
                理事会不能召集的，由1/5以上理事、监事、本会党组织或党建联络员向党建领导机关申请，由党建领导机关组织成立换届工作领导小组（或专门选举委员会），负责换届选举工作；
            </div>
            <div class="public_charity_text_paragraph">
                换届工作领导小组拟定换届方案，应在会员大会召开前2个月报党建领导机关审核；
            </div>
            <div class="public_charity_text_paragraph">
                经党建领导机关同意，召开会员大会，选举和罢免理事；
            </div>
            <div class="public_charity_text_paragraph">
                （三）根据会员大会的授权，理事会在届中可以增补、罢免部分理事，最高不超过原理事总数的1/5。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第二十七条</span>
                每个理事单位只能选派一名代表担任理事。单位调整理事代表，由其书面通知本会，报理事会备案。该单位同时为理事的，其代表一并调整。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第二十八条</span>
                理事的权利:
            </div>
            <div class="public_charity_text_paragraph">
                （一）理事会的选举权、被选举权和表决权；
            </div>
            <div class="public_charity_text_paragraph">
                （二）对本会工作情况、财务情况、重大事项的知情权、建议权和监督权；
            </div>
            <div class="public_charity_text_paragraph">
                （三）参与制定内部管理制度，提出意见建议；
            </div>
            <div class="public_charity_text_paragraph">
                （四）向理事长或理事会提出召开临时会议的建议权。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第二十九条</span>
                理事应当遵守法律、法规和本章程的规定，忠实履行职责、维护本会利益，并履行以下义务：
            </div>
            <div class="public_charity_text_paragraph">
                （一）出席理事会会议，执行理事会决议；
            </div>
            <div class="public_charity_text_paragraph">
                （二）在职责范围内行使权利，不越权；
            </div>
            <div class="public_charity_text_paragraph">
                （三）不利用理事职权谋取不正当利益；
            </div>
            <div class="public_charity_text_paragraph">
                （四）不从事损害本会合法利益的活动；
            </div>
            <div class="public_charity_text_paragraph">
                （五）不得泄露在任职期间所获得的涉及本会的保密信息，但法律、法规另有规定的除外;
            </div>
            <div class="public_charity_text_paragraph">
                （六）谨慎、认真、勤勉、独立行使被合法赋予的职权；
            </div>
            <div class="public_charity_text_paragraph">
                （七）接受监事对其履行职责的合法监督和合理建议。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第三十条</span>
               理事会的职权是：
            </div>
            <div class="public_charity_text_paragraph">
                （一）执行会员大会的决议；
            </div>
            <div class="public_charity_text_paragraph">
                （二）选举和罢免理事、负责人；
            </div>
            <div class="public_charity_text_paragraph">
                （三）决定名誉职务人选；
            </div>
            <div class="public_charity_text_paragraph">
                （四）筹备召开会员大会，负责换届选举工作；
            </div>
            <div class="public_charity_text_paragraph">
                （五）向会员大会报告工作和财务状况；
            </div>
            <div class="public_charity_text_paragraph">
                （六）决定设立、变更和终止分支机构、代表机构、办事机构和其他所属机构；
            </div>
            <div class="public_charity_text_paragraph">
                （七）决定副秘书长、各所属机构主要负责人的人选；
            </div>
            <div class="public_charity_text_paragraph">
                （八）领导本会各所属机构开展工作；
            </div>
            <div class="public_charity_text_paragraph">
                （九）审议年度工作报告和工作计划；
            </div>
            <div class="public_charity_text_paragraph">
                （十）审议年度财务预算、决算；
            </div>
            <div class="public_charity_text_paragraph">
                （十一）制定信息公开办法、财务管理制度、分支机构、代表机构管理办法等重要的管理制度；
            </div>
            <div class="public_charity_text_paragraph">
                （十二）决定本会负责人和工作人员的考核及薪酬管理办法；
            </div>
            <div class="public_charity_text_paragraph">
                （十三）决定其他重大事项。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第三十一条</span>
                理事会与会员大会任期相同。
            </div>
            <div class="public_charity_text_paragraph">
                <span>  第三十二条</span>
              理事会会议须有2／3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。
            </div>
            <div class="public_charity_text_paragraph">
               理事3次不出席理事会会议，自动丧失理事资格。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第三十三条</span>
                负责人由理事会采取无记名投票方式从理事中选举产生。罢免理事、负责人，须经到会理事2/3以上投票通过。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第三十四条</span>
                选举理事、负责人，按得票数确定当选人员，但当选的得票数不得低于总票数的2/3。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第三十五条</span>
                理事会每年至少召开1次会议，情况特殊的，可采用通讯形式召开。通讯会议不得决定以下事项：（一）负责人的调整；（二）理事单位的变更。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第三十六条</span>
                经理事长或者1/5的理事提议，应当召开临时理事会会议。
            </div>
            <div class="public_charity_text_paragraph">
                理事长不能主持临时理事会会议，由提议召集人推举本会一名负责人主持会议。
            </div>
            <div class="public_charity_text_assistant_assistant_title">第三节 常务理事会</div>
            <div class="public_charity_text_paragraph">
                <span>第三十七条</span>
                本会不设立常务理事会。
            </div>
            <div class="public_charity_text_assistant_assistant_title">第四节 负责人</div>
            <div class="public_charity_text_paragraph">
                <span>第三十八条</span>
                本会负责人包括理事长1名，副理事长3名，秘书长1名。
            </div>
            <div class="public_charity_text_paragraph">
                本会负责人应当具备下列条件：
            </div>
            <div class="public_charity_text_paragraph">
                （一）坚持中国共产党领导，拥护中国特色社会主义，坚决执行党的路线、方针、政策，具备良好的政治素质；
            </div>
            <div class="public_charity_text_paragraph">
                （二）遵纪守法，勤勉尽职，个人社会信用记录良好；
            </div>
            <div class="public_charity_text_paragraph">
                （三）具备相应的专业知识、经验和能力，熟悉行业情况，在本会业务领域有较大影响；
            </div>
            <div class="public_charity_text_paragraph">
                （四）身体健康，能正常履责，年龄不超过70周岁，秘书长为专职；
            </div>
            <div class="public_charity_text_paragraph">
                （五）具有完全民事行为能力；
            </div>
            <div class="public_charity_text_paragraph">
                （六）能够忠实、勤勉履行职责，维护本会和会员的合法权益；
            </div>
            <div class="public_charity_text_paragraph">
                （七）无法律法规、国家政策规定不得担任的其他情形。
            </div>
            <div class="public_charity_text_paragraph">
                理事长、秘书长不得兼任其他社会团体的理事长、秘书长，理事长和秘书长不得由同一人兼任，并不得来自于同一会员单位。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第三十九条</span>
                本会负责人任期与理事会相同，连任不超过2 届。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第四十条</span>
                理事长为本会法定代表人。
            </div>
            <div class="public_charity_text_paragraph">
                因特殊情况，经理事长推荐、理事会同意，报党建领导机关审核同意并经登记、管理机关批准后，可以由副理事长或秘书长担任法定代表人。聘任或向社会公开招聘的秘书长不得任本会法定代表人。
            </div>
            <div class="public_charity_text_paragraph">
                法定代表人代表本会签署有关重要文件。
            </div>
            <div class="public_charity_text_paragraph">
                本会法定代表人不兼任其他社团的法定代表人。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第四十一条</span>
                担任法定代表人的负责人被罢免或卸任后，不再履行本会法定代表人的职权。由本会在其被罢免或卸任后的20日内，报党建领导机关审核同意后，向登记机关办理变更登记。
            </div>
            <div class="public_charity_text_paragraph">
                原任法定代表人不予配合办理法定代表人变更登记的，本会可根据理事会同意变更的决议，报党建领导机关审核同意后，向登记机关申请变更登记。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第四十二条</span>
               理事长履行下列职责：
            </div>
            <div class="public_charity_text_paragraph">
                （一）召集和主持理事会；
            </div>
            <div class="public_charity_text_paragraph">
                （二）检查会员大会、理事会决议的落实情况；
            </div>
            <div class="public_charity_text_paragraph">
                （三）向会员大会、理事会报告工作；
            </div>
            <div class="public_charity_text_paragraph">
                理事长应每年向理事会进行述职。不能履行职责时，由其委托或理事会推选一名副理事长代为履行职责。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第四十三条</span>
                副理事长、秘书长协助理事长开展工作。秘书长行使下列职责：
            </div>
            <div class="public_charity_text_paragraph">
                （一）协调各机构开展工作；
            </div>
            <div class="public_charity_text_paragraph">
                （二）主持办事机构开展日常工作；
            </div>
            <div class="public_charity_text_paragraph">
                （三）处理其他日常事务。
            </div>
            <div class="public_charity_text_paragraph">
                （四）决定专职工作人员的聘用；
            </div>
            <div class="public_charity_text_paragraph">
                <span>第四十四条</span>
                会员大会、理事会会议应当制作会议纪要。形成决议的，应当制作书面决议，并由出席会议成员核签。会议纪要、会议决议应当以适当方式向会员通报或备查，并至少保存10年。
            </div>
            <div class="public_charity_text_paragraph">
                理事、负责人的选举结果须在20日内报党建领导机关审核，经同意，向管理机关备案并向会员通报或备查。
            </div>
            <div class="public_charity_text_assistant_assistant_title">第五节 监事会</div>
            <div class="public_charity_text_paragraph">
                <span>第四十五条</span>
                本会设立监事会，监事任期与理事任期相同，期满可以连任。监事会由3名监事组成。监事会设监事长1名，由监事会推举产生。监事长年龄不超过70周岁，连任不超过2 届。
            </div>
            <div class="public_charity_text_paragraph">
                本会接受并支持委派监事的监督指导。
            </div>
            <div class="public_charity_text_paragraph">
                <span>  第四十六条</span>
              监事的选举和罢免：
            </div>
            <div class="public_charity_text_paragraph">
                （一）由会员大会选举产生；
            </div>
            <div class="public_charity_text_paragraph">
                （二）监事的罢免依照其产生程序。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第四十七条</span>
                本会的负责人、理事、常务理事和本会的财务管理人员不得兼任监事。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第四十八条</span>
                监事会行使下列职权：
            </div>
            <div class="public_charity_text_paragraph">
                （一）列席理事会会议，并对决议事项提出质询或建议；
            </div>
            <div class="public_charity_text_paragraph">
                （二）对理事、负责人执行本会职务的行为进行监督，对严重违反本会章程或者会员大会决议的人员提出罢免建议；
            </div>
            <div class="public_charity_text_paragraph">
                （三）检查本会的财务报告，向会员大会报告监事会的工作和提出提案；
            </div>
            <div class="public_charity_text_paragraph">
                （四）对负责人、理事、财务管理人员损害本会利益的行为，要求其及时予以纠正；
            </div>
            <div class="public_charity_text_paragraph">
                （五）向党建领导机关、行业管理部门、登记机关、管理机关以及税务、会计主管部门反映本会工作中存在的问题；
            </div>
            <div class="public_charity_text_paragraph">
                （六）决定其他应由监事会审议的事项。
            </div>
            <div class="public_charity_text_paragraph">
                监事会每6个月至少召开1次会议。监事会会议须有2/3以上监事出席方能召开，其决议须经到会监事1/2以上通过方为有效。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第四十九条</span>
               监事应当遵守有关法律法规和本会章程，忠实、勤勉履行职责。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第五十条</span>
               监事会可以对本会开展活动情况进行调查；必要时，可以聘请会计师事务所等协助其工作。监事会行使职权所必需的费用，由本会承担。
            </div>
            <div class="public_charity_text_assistant_assistant_title">第六节 分支机构</div>
            <div class="public_charity_text_paragraph">
                <span>第五十一条</span>
               本会不设立地域性分支机构，不在分支机构、代表机构下再设立分支机构、代表机构。
            </div>
            <div class="public_charity_text_assistant_assistant_title">第七节 内部管理制度和矛盾解决机制</div>
            <div class="public_charity_text_paragraph">
                <span>第五十二条</span>
                本会建立各项内部管理制度，完善相关管理规程。建立《秦皇岛市邢台商会财务管理制度》、《秦皇岛市邢台商会第一届会员大会选举办法》等相关制度和文件。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第五十三条</span>
                本会建立健全证书、印章、档案、文件等内部管理制度，并将以上物品和资料妥善保管于本会场所，任何单位、个人不得非法侵占。管理人员调动工作或者离职时，必须与接管人员办清交接手续。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第五十四条</span>
               本会证书、印章遗失时，经理事会2/3以上理事表决通过，在公开发布的报刊上刊登遗失声明，可以向登记机关申请重新制发或刻制。如被个人非法侵占，应通过法律途径要求返还。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第五十五条</span>
                本会建立民主协商和内部矛盾解决机制。如发生内部矛盾不能经过协商解决的，可以通过调解、诉讼等途径依法解决。
            </div>

            <div class="public_charity_text_assistant_title">第六章资产管理、使用原则</div>

            <div class="public_charity_text_paragraph">
                <span>第五十六条</span>
                本会收入来源：
            </div>
            <div class="public_charity_text_paragraph">
                （一）会费；
            </div>
            <div class="public_charity_text_paragraph">
                （二）捐赠；
            </div>
            <div class="public_charity_text_paragraph">
                （三）政府资助；
            </div>
            <div class="public_charity_text_paragraph">
                （四）在核准的业务范围内开展活动、提供服务的收入；
            </div>
            <div class="public_charity_text_paragraph">
                （五）利息；
            </div>
            <div class="public_charity_text_paragraph">
                （六）其他合法收入。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第五十七条</span>
                本会按照国家有关规定收取会员会费。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第五十八条</span>
                本会的收入除用于与本会有关的、合理的支出外，全部用于本章程规定的业务范围和非营利事业。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第五十九条</span>
                本会执行《民间非营利组织会计制度》，建立严格的财务管理制度，保证会计资料合法、真实、准确、完整。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第六十条</span>
               本会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或者离职时，必须与接管人员办清交接手续。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第六十一条</span>
                本会的资产管理必须执行国家规定的财务管理制度，接受会员大会和有关部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第六十二条</span>
                本会重大资产配置、处置须经过会员大会或者理事会审议。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第六十三条</span>
                理事会决议违反法律、法规或章程规定，致使社会团体遭受损失的，参与审议的理事应当承担责任。但经证明在表决时反对并记载于会议记录的，该理事可免除责任。
            </div>
            <div class="public_charity_text_paragraph">
                <span>  第六十四条</span>
              本会换届或者更换法定代表人之前必须进行财务审计。
            </div>
            <div class="public_charity_text_paragraph">
                法定代表人在任期间，本社团发生违反《社会团体登记管理条例》和本章程的行为，法定代表人应当承担相关责任。因法定代表人失职，导致社会团体发生违法行为或社会团体财产损失的，法定代表人应当承担个人责任。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第六十五条</span>
              本会的全部资产及其增值为本会所有，任何单位、个人不得侵占、私分和挪用，也不得在会员中分配。
            </div>

            <div class="public_charity_text_assistant_assistant_title">第七节 内部管理制度和矛盾解决机制</div>

            <div class="public_charity_text_paragraph">
                <span> 第六十六条</span>
               本会依据有关政策法规，履行信息公开义务，建立信息公开制度，及时向会员公开年度工作报告、第三方机构出具的报告、会费收支情况以及经理事会研究认为有必要公开的其他信息，及时向社会公开登记事项、章程、组织机构、接受捐赠、信用承诺、政府转移或委托事项、可提供服务事项及运行情况等信息。
            </div>
            <div class="public_charity_text_paragraph">
                本会建立新闻发言人制度，经理事会通过，任命或指定12 名负责人作为新闻发言人，就本组织的重要活动、重大事件或热点问题，通过定期或不定期举行新闻发布会、吹风会、接受采访等形式主动回应社会关切。新闻发布内容应由本会法定代表人或主要负责人审定，确保正确的舆论导向。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第六十七条</span>
                本会建立年度报告制度，年度报告内容及时向社会公开，接受公众监督。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第六十八条</span>
              本会重点围绕服务内容、服务方式、服务对象和收费标准等建立信用承诺制度，并向社会公开信用承诺内容。
            </div>

            <div class="public_charity_text_assistant_title">第八章 章程的修改程序</div>

            <div class="public_charity_text_paragraph">
                <span>第六十九条</span>
                对本会章程的修改，由理事会表决通过，提交会员大会审议。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第七十条</span>
               本会修改的章程，经会员大会到会会员2/3以上表决通过后，报党建领导机关审核，经同意，在30日内报登记机关核准。
            </div>
            
            <div class="public_charity_text_assistant_title">第九章 终止程序及终止后的财产处理</div>

            <div class="public_charity_text_paragraph">
                <span> 第七十一条</span>
               本会终止动议由理事会提出，报会员大会表决通过。
            </div>
            <div class="public_charity_text_paragraph">
                <span> 第七十二条</span>
               本会终止前，应当依法成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第七十三条</span>
                本会经登记机关办理注销登记手续后即为终止。
            </div>
            <div class="public_charity_text_paragraph">
                <span>  第七十四条</span>
              本会终止后的剩余财产，在党建领导机关和登记、管理机关的监督下，按照国家有关规定，用于发展与本会宗旨相关的事业，或者捐赠给宗旨相近的社会组织。
            </div>

            <div class="public_charity_text_assistant_title">第十章 附则</div>

            <div class="public_charity_text_paragraph">
                <span>第七十五条</span>
               本章经2021年12月17日会员大会表决通过。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第七十六条</span>
              本章程的解释权属本会的理事会。
            </div>
            <div class="public_charity_text_paragraph">
                <span>第七十七条</span>
                本章程自登记机关核准之日起生效。
            </div>
            <div class="public_charity_text_paragraph">
                <span></span>
                
            </div>
            <div class="public_charity_text_paragraph">
                <span></span>
                
            </div>
            <div class="public_charity_text_paragraph">
                <span></span>
                
            </div>
            <div class="public_charity_text_paragraph">
                <span></span>
                
            </div>
            <div class="public_charity_text_paragraph">
                <span></span>
                
            </div>
            <div class="public_charity_text_paragraph">
                <span></span>
                
            </div>
        </div>
    </div>
</template>

<script>
import {
  defineComponent
} from "@/plugin/importCommonUse";
import { computed } from 'vue'
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
    setup() {
        let route = useRoute();
        let router = useRouter();
        let init = computed(()=>{ 

        })
        let skip = (url)=>{

        }
        return {
        };
    },
})
</script>
<style>
    .public_charity{
        color: #000;
    }
    /* 主标题 */
    .public_charity_text_title{
        letter-spacing:1px;
        font-size: 21.9px;
        font-weight: 600;
        font-family: 宋体;
        /* margin-bottom: 20px; */
        margin: 20px 0;
    }
    /* 副标题 */
    .public_charity_text_assistant_title{
        font-weight: 600;
        font-size: 15.9px;
        font-family: 黑体;
    }
    .public_charity_text_paragraph{
        text-indent: 2em;
        margin: 5px 10px 10px 10px;
        text-align: left;
        font-family: 仿宋;
        font-size: 15.9px;
        letter-spacing:1px;
    }
    .public_charity_text_paragraph span{
        font-weight: 600;
    }
    .public_charity_text_assistant_assistant_title{
        font-weight: 600;
        font-size: 15.9px;
        font-family: 仿宋;
        margin-top: 5px;
    }
</style>
